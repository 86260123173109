import React from 'react';
import { FaChartLine, FaNewspaper, FaCalendarAlt, FaSearchDollar, FaGlassCheers, FaComments, FaVideo, FaShoppingCart, FaBullhorn } from 'react-icons/fa';

const services = [
  { 
    name: 'Nákup médií', 
    icon: FaShoppingCart, 
    description: 'Efektivní nákup mediálního prostoru pro vaše kampaně. Optimalizujeme váš mediální rozpočet pro maximální dosah a efektivitu.'
  },
  { 
    name: 'Eventy', 
    icon: FaGlassCheers, 
    description: 'Organizace nezapomenutelných akcí a událostí. Od firemních večírků po velké konference - postaráme se o každý detail.'
  },
  { 
    name: 'Marketingové strategie', 
    icon: FaChartLine, 
    description: 'Tvorba komplexních marketingových strategií. Analyzujeme trh a vytváříme strategie šité na míru vašemu byznysu.'
  },
  { 
    name: 'Public relations', 
    icon: FaNewspaper, 
    description: 'Budování a udržování pozitivního obrazu vaší značky. Pomáháme vám komunikovat s médii a veřejností efektivně a profesionálně.'
  },
  { 
    name: 'Plánování médií', 
    icon: FaCalendarAlt, 
    description: 'Optimalizace mediálního plánu pro maximální dosah. Vytváříme mediální plány, které zasáhnou vaši cílovou skupinu ve správný čas.'
  },
  { 
    name: 'Výzkum médií', 
    icon: FaSearchDollar, 
    description: 'Analýza mediálního trhu a cílových skupin. Poskytujeme hloubkové analýzy pro informované rozhodování o vašich kampaních.'
  },
  { 
    name: 'Poradenství a konzultace', 
    icon: FaComments, 
    description: 'Expertní poradenství v oblasti marketingu a médií. Naši experti jsou připraveni pomoci vám s jakýmkoliv marketingovým problémem.'
  },
  { 
    name: 'Produkce', 
    icon: FaVideo, 
    description: 'Tvorba audiovizuálního obsahu pro vaše kampaně. Od konceptu po finální produkt - vytváříme obsah, který zaujme.'
  },
  { 
    name: 'Digitální marketing', 
    icon: FaBullhorn, 
    description: 'Komplexní řešení pro vaši online prezentaci. SEO, PPC, sociální média - pokrýváme všechny aspekty digitálního marketingu.'
  },
];

const Services = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-5xl font-bold mb-12 text-center text-yellow-400">Naše služby</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-yellow-400/10 rounded-lg p-6 flex flex-col items-center text-center border border-yellow-400/30 backdrop-blur-sm transition-all duration-300 hover:bg-yellow-400/20">
            <service.icon className="text-5xl mb-4 text-yellow-400" />
            <h2 className="text-2xl font-semibold mb-4 text-yellow-400 glow-effect">{service.name}</h2>
            <p className="text-yellow-200">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
