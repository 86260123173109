import React, { useState, useMemo } from 'react'
import { Shapes, Type, Zap, MessageSquare } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import { Routes, Route, useNavigate } from 'react-router-dom'
import Services from './pages/Services'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

function AppContent() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const handleNavigation = (path: string) => {
    setIsMenuOpen(false)
    navigate(path)
  }

  // Vytvoříme pole s různými typy ikon
  const icons = [Shapes, Type, Zap, MessageSquare]

  // Použijeme useMemo pro vytvoření a uložení piktogramů
  const floatingObjects = useMemo(() => {
    return [...Array(20)].map((_, i) => {
      const IconComponent = icons[i % icons.length]
      return {
        Icon: IconComponent,
        style: {
          left: `${Math.random() * 100}vw`,
          top: `${Math.random() * 100}vh`,
          animationDuration: `${Math.random() * 10 + 5}s`,
          animationDelay: `${Math.random() * 5}s`,
        },
        size: Math.random() * 24 + 24,
      }
    })
  }, [])

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      {/* Floating objects */}
      {floatingObjects.map((object, i) => (
        <div
          key={i}
          className="absolute text-yellow-400 opacity-50 animate-float"
          style={object.style}
        >
          <object.Icon size={object.size} />
        </div>
      ))}

      {/* Menu button */}
      <button
        className="fixed top-4 right-4 z-50 px-4 py-2 bg-yellow-400 text-black font-bold rounded-full text-lg hover:bg-white transition-colors duration-300"
        onClick={toggleMenu}
      >
        {isMenuOpen ? 'Zavřít' : 'Menu'}
      </button>

      {/* Unconventional menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.nav
            initial={{ scale: 0, opacity: 0, x: '100%', y: '-100%' }}
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            exit={{ scale: 0, opacity: 0, x: '100%', y: '-100%' }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="fixed inset-0 bg-yellow-400 flex flex-col items-center justify-center z-40"
          >
            <ul className="text-black text-4xl font-bold space-y-8">
              <motion.li
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ delay: 0.2 }}
                className="hover:text-white transition-colors duration-300 cursor-pointer"
                onClick={() => handleNavigation('/')}
              >
                Home
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ delay: 0.2 }}
                className="hover:text-white transition-colors duration-300 cursor-pointer"
                onClick={() => handleNavigation('/o-nas')}
              >
                O nás
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ delay: 0.2 }}
                className="hover:text-white transition-colors duration-300 cursor-pointer"
                onClick={() => handleNavigation('/sluzby')}
              >
                Služby
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ delay: 0.2 }}
                className="hover:text-white transition-colors duration-300 cursor-pointer"
                onClick={() => handleNavigation('/kontakt')}
              >
                Kontakt
              </motion.li>
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>

      {/* Glitch effect overlay */}
      <div className="fixed inset-0 pointer-events-none z-30">
        <div className="absolute inset-0 bg-black opacity-20 mix-blend-color-dodge"></div>
        <div className="absolute inset-0 bg-yellow-400 opacity-10 mix-blend-color-burn"></div>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/o-nas" element={<About />} />
        <Route path="/sluzby" element={<Services />} />
        <Route path="/kontakt" element={<Contact />} />
      </Routes>
    </div>
  )
}

const Home = () => (
  <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
    <h1 className="text-4xl sm:text-6xl md:text-8xl font-bold mb-8 md:mb-12 tracking-tight relative">
      <span className="relative z-10">suPR komunikace</span>
      <span className="absolute inset-0 z-0 text-yellow-400 blur-[3px] sm:blur-[6px] opacity-70">suPR komunikace</span>
      <span className="absolute inset-0 z-0 text-yellow-400 blur-[6px] sm:blur-[12px] opacity-50">suPR komunikace</span>
    </h1>
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="relative p-4 sm:p-6 rounded-lg max-w-2xl border border-yellow-400/30 bg-yellow-400/10 backdrop-blur-sm"
    >
      <p className="text-base sm:text-xl mb-4 leading-relaxed">
        Jsme <span className="font-bold text-yellow-400">suPR</span> tým, který přetváří vaše vize v realitu. Od kreativních konceptů po precizní exekuci - naše full-service agentura je vaším partnerem pro komunikaci, která překonává očekávání.
      </p>
    </motion.div>
  </div>
)

const About = () => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-center min-h-screen py-16 px-4">
      <div className="max-w-4xl text-center">
        <h1 className="text-5xl font-bold mb-8 text-yellow-400 glow-effect">Příběh suPR evoluce</h1>
        <div className="space-y-6 text-lg">
          <p>
            V džungli marketingu, kde každý den přežívají jen ti nejsilnější, se zrodil tým, který se rozhodl změnit pravidla hry. Nejsme jen další PR agentura - jsme suPR síla, která přetváří komunikační krajinu s precizností švýcarských hodinek a kreativitou renesančních mistrů.
          </p>
          <p>
            Naše DNA je spletená z vláken inovace, integrity a intenzivní vášně pro výsledky. Každý člen našeho týmu je jako vzácný klenot v koruně krále Midase - dotkne se projektu a ten začne zářit zlatem úspěchu. Od stratégů s myslí Sherlocka Holmese po kreativce s imaginací Salvadora Dalího, náš tým je symfonií talentů, která hraje v dokonalé harmonii.
          </p>
          <p>
            V suPR světě neexistují problémy, jen výzvy čekající na své suPR-hrdinské řešení. Naše firemní hodnoty nejsou jen slova na zdi - jsou to mantry, které žijeme každý den:
          </p>
          <ul className="list-disc list-inside text-left mb-6">
            <li><span className="font-bold text-yellow-400">suPR Integrita:</span> Pravda je naší měnou, důvěra naším zlatým standardem.</li>
            <li><span className="font-bold text-yellow-400">suPR Inovace:</span> Ztřek je pro nás už včerejšek - jsme vždy o krok napřed.</li>
            <li><span className="font-bold text-yellow-400">suPR Impakt:</span> Neměříme úspěch v číslech, ale v transformacích, které přinášíme.</li>
          </ul>
          <p>
            Pro naše klienty nejsme jen agenturou - jsme alchymisty reputace, kouzelníky vnímání a architekty úspěchu. S námi se vaše značka nestane jen viditelnou - stane se nezapomenutelnou. Naše strategie jsou jako dobře namíchaný koktejl: osvěžující, s nečekaným twistem a vždy s efektem, který vás rozechvěje až do konečků prstů.
          </p>
          <div className="mt-8 p-6 border-2 border-yellow-400 rounded-lg">
            <p className="text-xl font-bold text-yellow-400">
              Připraveni vystoupat na vrchol komunikačního Olympu? S námi není otázkou zda, ale kdy. Vítejte ve světě suPR - kde každý den je příležitostí překonat sebe sama a kde každá výzva je jen dalším schůdkem k velikosti.
            </p>
          </div>
          <button 
            onClick={() => navigate('/kontakt')} 
            className="mt-8 px-6 py-3 bg-yellow-400 text-black font-bold rounded-full text-lg hover:bg-white transition-colors duration-300"
          >
            Kontaktujte nás
          </button>
        </div>
      </div>
    </div>
  )
}

const Contact = () => (
  <div className="flex items-center justify-center min-h-screen py-16 px-4">
    <div className="max-w-2xl text-center">
      <h1 className="text-5xl font-bold mb-8 text-yellow-400 glow-effect">Kontakt</h1>
      <div className="space-y-6 text-lg">
        <p className="text-2xl font-semibold text-yellow-400">
          Připraveni na suPR výsledky? Kontaktujte nás!
        </p>
        <div className="flex justify-center space-x-8 mb-6">
          <div className="flex flex-col items-center bg-yellow-400/10 rounded-lg p-4 border border-yellow-400/30 backdrop-blur-sm">
            <FaEnvelope className="text-4xl text-yellow-400 mb-2" />
            <a href="mailto:tvoje@supr.agency" className="hover:text-yellow-400 transition-colors duration-300">
              tvoje@supr.agency
            </a>
          </div>
          <div className="flex flex-col items-center bg-yellow-400/10 rounded-lg p-4 border border-yellow-400/30 backdrop-blur-sm">
            <FaPhone className="text-4xl text-yellow-400 mb-2" />
            <a href="tel:+420722221122" className="hover:text-yellow-400 transition-colors duration-300">
              +420 722 22 11 22
            </a>
          </div>
        </div>
        <div className="bg-yellow-400/10 rounded-lg p-6 border border-yellow-400/30 backdrop-blur-sm">
          <h2 className="text-2xl font-bold mb-4 text-yellow-400">suPR komunikace s.r.o.</h2>
          <p><strong>IČO:</strong> 11740582</p>
          <p><strong>Datová schránka:</strong> hqfcazf</p>
          <p><strong>Sídlo:</strong> U Pražského mostu 2213/2, 586 01 Jihlava</p>
        </div>
      </div>
    </div>
  </div>
)

export default AppContent
